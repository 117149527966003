// vim: set et sw=2 ts=2 ff=unix fileencoding=utf-8 ft=javascript:
// @ts-check

export default (() => {
  const {
    utils: { bindEvent, elementInView, throttle, unbindEvent, loadScriptAsync },
    base: { viewport }
  } = window.wafer
  const VALIDATE_DELAY = 50
  const IN_VIEW_X_OFFSET = 100
  const IN_VIEW_Y_OFFSET = 100
  const YAP_AD_LIBRARY = 'https://s.yimg.com/av/yap/ga/yap.js'

  class GeminiAd {
    constructor({ selector }) {
      this.containerElement = document.querySelector(selector)
      if (!this.containerElement) {
        return
      }

      this.isInitialised = false
      this.validateWithThrottle = throttle(this.validate, VALIDATE_DELAY, this)

      this.init()
    }

    init() {
      bindEvent(window, 'scroll', this.validateWithThrottle)
      this.validateWithThrottle()
    }

    validate() {
      if (
        !this.isInitialised &&
        elementInView(
          this.containerElement,
          {
            offsetX: IN_VIEW_X_OFFSET,
            offsetY: IN_VIEW_Y_OFFSET
          },
          viewport
        )
      ) {
        unbindEvent(window, 'scroll', this.validateWithThrottle)
        this.isInitialised = true
        this.loadGeminiAd()
      }
    }

    loadGeminiAd() {
      const adContainer = this.containerElement.querySelector('.ad-gemini-rm')
      if (adContainer === null) {
        return
      }

      const unitCode = adContainer.getAttribute('data-unit-code')
      const apiKey = adContainer.getAttribute('data-api-key')
      const thumbSize = adContainer.getAttribute('data-thumb-size')
      if (unitCode === null || apiKey === null) {
        throw new Error('Gemini ad is misconfigured.')
      }

      window.top.adUnitCode = window.top.adUnitCode || []
      window.top.adUnitCode.push(unitCode)
      window.top.apiKey = apiKey

      window.yapDebug = window.localStorage.debug !== undefined

      // Final sanity check to avoid loading dupe ads
      if (this.containerElement.querySelectorAll('.yap-loaded').length === 0) {
        loadScriptAsync({ src: YAP_AD_LIBRARY }, () => {
          document.documentElement.firstElementChild
            .appendChild(document.createElement('style'))
            .sheet.insertRule(
              `
              .has-gemini-ad > .module-wrap .ad-gemini-rm .list-post-image {
                ${thumbSize}
              }
            `,
              0
            )
        })
      }
    }
  }

  return GeminiAd
})()
